/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Candidate } from '../models/Candidate'
import { client } from '../plugins/axios-plugin'
import { PrioritisedCity, PrioritisedJob } from '~/models'

export default class CandidateService {
    static async fetchCandidate(id: number): Promise<any> {
        const response = await client.get(`/candidates/${id}/`)
        return response.data
    }

    static async getCandidate(id: number): Promise<Candidate> {
        const candidate = await this.fetchCandidate(id)
        return Candidate.fromObject(candidate)
    }

    static async putCandidate(candidate: Candidate): Promise<any> {
        const response = await client.patch(`/candidates/${candidate.id}/`, candidate)
        return response?.data
    }

    static async setCandidate(candidate: Candidate): Promise<Candidate> {
        const patchedCandidate = await this.putCandidate(candidate)
        return Candidate.fromObject(patchedCandidate)
    }

    static async patchCandidate(
        id: number,
        field: string,
        value: string | Array<any> | boolean | number | unknown
    ): Promise<Candidate> {
        const response = await client.patch(`/candidates/${id}/`, { [field]: value })
        return response?.data
    }

    static async patchCandidateStatus(candidateId: number, action: string, sequence: number): Promise<Candidate> {
        const response = await client.patch(`/candidates/${candidateId}/next_status/`, { action, sequence })
        return response?.data
    }

    // if field is of type string, candidate[field] will throw an error because field should be a keyof candidate, so here we need to say that field is a key of candidate AND a string because we pass a string to setCandidateField.

    static async setCandidateField(candidate: Candidate, field: keyof Candidate & string): Promise<Candidate> {
        const value = candidate[field]
        const patchedCandidate = await this.patchCandidate(candidate.id, field, value)
        return Candidate.fromObject(patchedCandidate)
    }

    static async setCandidateFields(candidate: Candidate, fields: Array<keyof Candidate & string>): Promise<Candidate> {
        const data = {} as any
        fields.map((field) => (data[field] = candidate[field]))
        const response = await client.patch(`/candidates/${candidate.id}/`, data)
        return Candidate.fromObject(response.data)
    }

    static async setCandidateStatus(candidateId: number, action: string, sequence: number): Promise<Candidate> {
        const patchedCandidate = await this.patchCandidateStatus(candidateId, action, sequence)
        return Candidate.fromObject(patchedCandidate)
    }

    static async hijackCandidate(candidateUser: number): Promise<string> {
        const response = await client.get(`/users/log-as/?user=${candidateUser}`)
        return response?.data
    }

    static async launchAutoSearch(candidateId: number): Promise<any> {
        await client.post(`/candidates/${candidateId}/fits/`)
    }

    static async summarizeCandidateProfile(candidateId: number): Promise<any> {
        const response = await client.post(`/candidates/${candidateId}/ai_summary/`)
        return response?.data
    }

    static async getCandidateStatuses(candidateId: number): Promise<string> {
        const response = await client.get(`/candidates/${candidateId}/statuses`)
        return response?.data?.results
    }

    static async getCandidateAcademy(candidateId: number): Promise<string> {
        const response = await client.get(`/candidates/${candidateId}/academy/`)
        return response?.data
    }

    static async sendBapTrigger(candidateId: number): Promise<string> {
        const response = await client.post(`/candidates/${candidateId}/academy/send-bap-trigger/`)
        return response?.data
    }

    static async sendAppointmentTrigger(candidateId: number): Promise<string> {
        const response = await client.post(`/candidates/${candidateId}/send_appointment_trigger/`)
        return response?.data
    }

    static async postCity(newCity: any): Promise<any> {
        const response = await client.post(`candidates/${newCity.candidate}/cities-wanted/`, newCity)
        return response?.data
    }

    static async addCity(newCity: PrioritisedCity): Promise<PrioritisedCity> {
        const city = await this.postCity(newCity)
        return PrioritisedCity.toPrioritisedCity(city)
    }

    static async patchCity(data: any): Promise<any> {
        const response = await client.patch(`candidates/${data.candidate}/cities-wanted/${data.id}/`, {
            [data.field]: data.value,
        })
        return response.data
    }

    static async editCity(data: any): Promise<PrioritisedCity> {
        const city = await this.patchCity(data)
        return PrioritisedCity.toPrioritisedCity(city)
    }

    static async removeCity(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/cities-wanted/${id}/`)
    }

    static async postTechno(newTechnology: any): Promise<any> {
        const response = await client.post(`candidates/${newTechnology.candidate}/technologies/`, newTechnology)
        return response?.data
    }

    static async patchTechno(techno: any): Promise<any> {
        const response = await client.patch(`candidates/${techno.candidate}/technologies/${techno.id}/`, techno.data)
        return response.data
    }

    static async removeTechno(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/technologies/${id}/`)
    }

    static async postBusinessModel(newBusinessModel: any): Promise<any> {
        const response = await client.post(
            `candidates/${newBusinessModel.candidate}/business-models/`,
            newBusinessModel
        )
        return response?.data
    }

    static async patchBusinessModel(businessModel: any): Promise<any> {
        const response = await client.patch(
            `candidates/${businessModel.candidate}/business-models/${businessModel.id}/`,
            businessModel.data
        )
        return response.data
    }

    static async removeBusinessModel(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/business-models/${id}/`)
    }

    static async postLanguage(newLanguage: any): Promise<any> {
        const response = await client.post(`candidates/${newLanguage.candidate}/languages/`, newLanguage)
        return response?.data
    }

    static async patchLanguage(language: any): Promise<any> {
        const response = await client.patch(`candidates/${language.candidate}/languages/${language.id}/`, language.data)
        return response.data
    }

    static async removeLanguage(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/languages/${id}/`)
    }

    static async postMaturity(newMaturity: any): Promise<any> {
        const response = await client.post(`candidates/${newMaturity.candidate}/maturities/`, newMaturity)
        return response?.data
    }

    static async patchMaturity(maturity: any): Promise<any> {
        const response = await client.patch(
            `candidates/${maturity.candidate}/maturities/${maturity.id}/`,
            maturity.data
        )
        return response.data
    }

    static async removeMaturity(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/maturities/${id}/`)
    }

    static async postIndustry(newIndustry: any): Promise<any> {
        const response = await client.post(`candidates/${newIndustry.candidate}/industries/`, newIndustry)
        return response?.data
    }

    static async patchIndustry(industry: any): Promise<any> {
        const response = await client.patch(
            `candidates/${industry.candidate}/industries/${industry.id}/`,
            industry.data
        )
        return response.data
    }

    static async removeIndustry(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/industries/${id}/`)
    }

    static async postSegmentation(newSegementation: any): Promise<any> {
        const response = await client.post(`candidates/${newSegementation.candidate}/segmentations/`, newSegementation)
        return response?.data
    }

    static async patchSegmentation(segmentation: any): Promise<any> {
        const response = await client.patch(
            `candidates/${segmentation.candidate}/segmentations/${segmentation.id}/`,
            segmentation.data
        )
        return response.data
    }

    static async removeSegmentation(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/segmentations/${id}/`)
    }

    static async postJob(newJob: any): Promise<any> {
        const response = await client.post(`candidates/${newJob.candidate}/jobs-wanted/`, newJob)
        return response?.data
    }

    static async addJob(newJob: PrioritisedJob): Promise<PrioritisedJob> {
        const job = await this.postJob(newJob)
        return PrioritisedJob.toPrioritisedJob(job)
    }

    static async patchJob(data: any): Promise<any> {
        const response = await client.patch(`candidates/${data.candidate}/jobs-wanted/${data.id}/`, {
            [data.field]: data.value,
        })
        return response.data
    }

    static async editJob(data: any): Promise<PrioritisedJob> {
        const job = await this.patchJob(data)
        return PrioritisedJob.toPrioritisedJob(job)
    }

    static async removeJob(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/jobs-wanted/${id}/`)
    }

    static async getCandidateMatchesTalks(candidateId: number) {
        const response = await client.get(`candidates/${candidateId}/matches_talks/`)
        return response?.data?.results
    }

    // listing

    static async getCandidates(params: any): Promise<any> {
        const response = await client.get('/candidates/', { params })
        return response.data
    }

    static async getCandidateReferrals(params: any): Promise<any> {
        const response = await client.get(`/candidates/referrals/`, { params })
        return response.data
    }

    static async getCandidateWithoutReferrals(params: any): Promise<any> {
        const response = await client.get(`/candidates/?status=CAND_REFERRALS_PENDING&no_referrals=True`, { params })
        return response.data
    }
}
