import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _720ce3fe = () => interopDefault(import('../pages/candidate.vue' /* webpackChunkName: "pages/candidate" */))
const _80252ea2 = () => interopDefault(import('../pages/candidate/_id/index.vue' /* webpackChunkName: "pages/candidate/_id/index" */))
const _a4a176ee = () => interopDefault(import('../pages/candidate/_id/academy.vue' /* webpackChunkName: "pages/candidate/_id/academy" */))
const _1cd5ddc2 = () => interopDefault(import('../pages/candidate/_id/activity.vue' /* webpackChunkName: "pages/candidate/_id/activity" */))
const _0854e7ac = () => interopDefault(import('../pages/candidate/_id/autoSearch.vue' /* webpackChunkName: "pages/candidate/_id/autoSearch" */))
const _c50e5ef6 = () => interopDefault(import('../pages/candidate/_id/general.vue' /* webpackChunkName: "pages/candidate/_id/general" */))
const _06b6775e = () => interopDefault(import('../pages/candidate/_id/interview.vue' /* webpackChunkName: "pages/candidate/_id/interview" */))
const _d2e72af2 = () => interopDefault(import('../pages/candidate/_id/matchesTalks.vue' /* webpackChunkName: "pages/candidate/_id/matchesTalks" */))
const _76d7c85a = () => interopDefault(import('../pages/candidate/_id/referrals.vue' /* webpackChunkName: "pages/candidate/_id/referrals" */))
const _26e3c97b = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _1676c3cc = () => interopDefault(import('../pages/company/_id/contacts.vue' /* webpackChunkName: "pages/company/_id/contacts" */))
const _4b53a4ff = () => interopDefault(import('../pages/company/_id/general.vue' /* webpackChunkName: "pages/company/_id/general" */))
const _1969decf = () => interopDefault(import('../pages/company/_id/jobs.vue' /* webpackChunkName: "pages/company/_id/jobs" */))
const _18cc4e5e = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _4fecebbb = () => interopDefault(import('../pages/job.vue' /* webpackChunkName: "pages/job" */))
const _1fd079a0 = () => interopDefault(import('../pages/job/_id/autoSearch.vue' /* webpackChunkName: "pages/job/_id/autoSearch" */))
const _367b673f = () => interopDefault(import('../pages/job/_id/general.vue' /* webpackChunkName: "pages/job/_id/general" */))
const _31e583ec = () => interopDefault(import('../pages/job/_id/missionSheet.vue' /* webpackChunkName: "pages/job/_id/missionSheet" */))
const _7a8ba54a = () => interopDefault(import('../pages/job/_id/permissions.vue' /* webpackChunkName: "pages/job/_id/permissions" */))
const _ee67cd52 = () => interopDefault(import('../pages/job/_id/pitch.vue' /* webpackChunkName: "pages/job/_id/pitch" */))
const _1b4f69cd = () => interopDefault(import('../pages/job/_id/ratings.vue' /* webpackChunkName: "pages/job/_id/ratings" */))
const _c4f3b032 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7eebea0c = () => interopDefault(import('../pages/ai_tools/aiTools.vue' /* webpackChunkName: "pages/ai_tools/aiTools" */))
const _71bbc4bc = () => interopDefault(import('../pages/ai_tools/kiaditca.js' /* webpackChunkName: "pages/ai_tools/kiaditca" */))
const _479e9d85 = () => interopDefault(import('../pages/listing/candidate-admitted.vue' /* webpackChunkName: "pages/listing/candidate-admitted" */))
const _4a98b80a = () => interopDefault(import('../pages/listing/candidate-applications.vue' /* webpackChunkName: "pages/listing/candidate-applications" */))
const _680b0ec4 = () => interopDefault(import('../pages/listing/candidate-applications/interested.vue' /* webpackChunkName: "pages/listing/candidate-applications/interested" */))
const _067fc59b = () => interopDefault(import('../pages/listing/candidate-applications/new.vue' /* webpackChunkName: "pages/listing/candidate-applications/new" */))
const _590db772 = () => interopDefault(import('../pages/listing/candidate-applications/opport-check.vue' /* webpackChunkName: "pages/listing/candidate-applications/opport-check" */))
const _2020bd4b = () => interopDefault(import('../pages/listing/candidate-applications/refused.vue' /* webpackChunkName: "pages/listing/candidate-applications/refused" */))
const _bf91beb6 = () => interopDefault(import('../pages/listing/candidate-db.vue' /* webpackChunkName: "pages/listing/candidate-db" */))
const _26bc7ec2 = () => interopDefault(import('../pages/listing/candidate-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals" */))
const _70b95eec = () => interopDefault(import('../pages/listing/candidate-referrals/no-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals/no-referrals" */))
const _19ba0a84 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-created.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-created" */))
const _52b215ed = () => interopDefault(import('../pages/listing/candidate-referrals/ref-pending.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-pending" */))
const _05db08c4 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-requested.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-requested" */))
const _45a3f54f = () => interopDefault(import('../pages/listing/candidate-search.vue' /* webpackChunkName: "pages/listing/candidate-search" */))
const _3d9c241a = () => interopDefault(import('../pages/listing/jobs.vue' /* webpackChunkName: "pages/listing/jobs" */))
const _61b3f626 = () => interopDefault(import('../pages/listing/matches.vue' /* webpackChunkName: "pages/listing/matches" */))
const _77397256 = () => interopDefault(import('../pages/listing/matches/candidate-check.vue' /* webpackChunkName: "pages/listing/matches/candidate-check" */))
const _3806ac19 = () => interopDefault(import('../pages/listing/matches/check-before-admission.vue' /* webpackChunkName: "pages/listing/matches/check-before-admission" */))
const _8ca04502 = () => interopDefault(import('../pages/listing/matches/client-check.vue' /* webpackChunkName: "pages/listing/matches/client-check" */))
const _fba03f72 = () => interopDefault(import('../pages/listing/matches/hired.vue' /* webpackChunkName: "pages/listing/matches/hired" */))
const _937f2254 = () => interopDefault(import('../pages/listing/matches/interested-before-admission.vue' /* webpackChunkName: "pages/listing/matches/interested-before-admission" */))
const _65f755b1 = () => interopDefault(import('../pages/listing/matches/internal-check.vue' /* webpackChunkName: "pages/listing/matches/internal-check" */))
const _13dee3f2 = () => interopDefault(import('../pages/listing/matches/interview-steps.vue' /* webpackChunkName: "pages/listing/matches/interview-steps" */))
const _4334369a = () => interopDefault(import('../pages/listing/matches/offer.vue' /* webpackChunkName: "pages/listing/matches/offer" */))
const _92cbc132 = () => interopDefault(import('../pages/listing/matches/refused.vue' /* webpackChunkName: "pages/listing/matches/refused" */))
const _5842f660 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/candidate",
    component: _720ce3fe,
    name: "candidate",
    children: [{
      path: ":id",
      component: _80252ea2,
      name: "candidate-id"
    }, {
      path: ":id?/academy",
      component: _a4a176ee,
      name: "candidate-id-academy"
    }, {
      path: ":id?/activity",
      component: _1cd5ddc2,
      name: "candidate-id-activity"
    }, {
      path: ":id?/autoSearch",
      component: _0854e7ac,
      name: "candidate-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _c50e5ef6,
      name: "candidate-id-general"
    }, {
      path: ":id?/interview",
      component: _06b6775e,
      name: "candidate-id-interview"
    }, {
      path: ":id?/matchesTalks",
      component: _d2e72af2,
      name: "candidate-id-matchesTalks"
    }, {
      path: ":id?/referrals",
      component: _76d7c85a,
      name: "candidate-id-referrals"
    }]
  }, {
    path: "/company",
    component: _26e3c97b,
    name: "company",
    children: [{
      path: ":id?/contacts",
      component: _1676c3cc,
      name: "company-id-contacts"
    }, {
      path: ":id?/general",
      component: _4b53a4ff,
      name: "company-id-general"
    }, {
      path: ":id?/jobs",
      component: _1969decf,
      name: "company-id-jobs"
    }]
  }, {
    path: "/home",
    component: _18cc4e5e,
    name: "home"
  }, {
    path: "/job",
    component: _4fecebbb,
    name: "job",
    children: [{
      path: ":id?/autoSearch",
      component: _1fd079a0,
      name: "job-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _367b673f,
      name: "job-id-general"
    }, {
      path: ":id?/missionSheet",
      component: _31e583ec,
      name: "job-id-missionSheet"
    }, {
      path: ":id?/permissions",
      component: _7a8ba54a,
      name: "job-id-permissions"
    }, {
      path: ":id?/pitch",
      component: _ee67cd52,
      name: "job-id-pitch"
    }, {
      path: ":id?/ratings",
      component: _1b4f69cd,
      name: "job-id-ratings"
    }]
  }, {
    path: "/login",
    component: _c4f3b032,
    name: "login"
  }, {
    path: "/ai_tools/aiTools",
    component: _7eebea0c,
    name: "ai_tools-aiTools"
  }, {
    path: "/ai_tools/kiaditca",
    component: _71bbc4bc,
    name: "ai_tools-kiaditca"
  }, {
    path: "/listing/candidate-admitted",
    component: _479e9d85,
    name: "listing-candidate-admitted"
  }, {
    path: "/listing/candidate-applications",
    component: _4a98b80a,
    name: "listing-candidate-applications",
    children: [{
      path: "interested",
      component: _680b0ec4,
      name: "listing-candidate-applications-interested"
    }, {
      path: "new",
      component: _067fc59b,
      name: "listing-candidate-applications-new"
    }, {
      path: "opport-check",
      component: _590db772,
      name: "listing-candidate-applications-opport-check"
    }, {
      path: "refused",
      component: _2020bd4b,
      name: "listing-candidate-applications-refused"
    }]
  }, {
    path: "/listing/candidate-db",
    component: _bf91beb6,
    name: "listing-candidate-db"
  }, {
    path: "/listing/candidate-referrals",
    component: _26bc7ec2,
    name: "listing-candidate-referrals",
    children: [{
      path: "no-referrals",
      component: _70b95eec,
      name: "listing-candidate-referrals-no-referrals"
    }, {
      path: "ref-created",
      component: _19ba0a84,
      name: "listing-candidate-referrals-ref-created"
    }, {
      path: "ref-pending",
      component: _52b215ed,
      name: "listing-candidate-referrals-ref-pending"
    }, {
      path: "ref-requested",
      component: _05db08c4,
      name: "listing-candidate-referrals-ref-requested"
    }]
  }, {
    path: "/listing/candidate-search",
    component: _45a3f54f,
    name: "listing-candidate-search"
  }, {
    path: "/listing/jobs",
    component: _3d9c241a,
    name: "listing-jobs"
  }, {
    path: "/listing/matches",
    component: _61b3f626,
    name: "listing-matches",
    children: [{
      path: "candidate-check",
      component: _77397256,
      name: "listing-matches-candidate-check"
    }, {
      path: "check-before-admission",
      component: _3806ac19,
      name: "listing-matches-check-before-admission"
    }, {
      path: "client-check",
      component: _8ca04502,
      name: "listing-matches-client-check"
    }, {
      path: "hired",
      component: _fba03f72,
      name: "listing-matches-hired"
    }, {
      path: "interested-before-admission",
      component: _937f2254,
      name: "listing-matches-interested-before-admission"
    }, {
      path: "internal-check",
      component: _65f755b1,
      name: "listing-matches-internal-check"
    }, {
      path: "interview-steps",
      component: _13dee3f2,
      name: "listing-matches-interview-steps"
    }, {
      path: "offer",
      component: _4334369a,
      name: "listing-matches-offer"
    }, {
      path: "refused",
      component: _92cbc132,
      name: "listing-matches-refused"
    }]
  }, {
    path: "/",
    component: _5842f660,
    name: "index"
  }, {
    path: "/company/:id/",
    redirect: "/company/:id/general",
    name: "companyId"
  }, {
    path: "/job/:id/",
    redirect: "/job/:id/general",
    name: "jobId"
  }, {
    path: "/listing/matches/",
    redirect: "/listing/matches/internal-check",
    name: "listingMatches"
  }, {
    path: "/listing/application/",
    redirect: "/listing/candidate-applications/new",
    name: "candidateListing"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
